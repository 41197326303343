.text-warning {
    color: #F50057!important;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

table {
    border-collapse: collapse;
}

.table .thead-dark th {
    color: #fff;
    background-color: #c1c1c1;
    border-color: #c1c1c1;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

th {
    text-align: inherit;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
}

.cardx {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.text-center {
    text-align: center!important;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: .75rem;
}

/* width */

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #C1C1C1;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: rgb(182, 181, 181)
}

#applicantInfoSection {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.terms {
    font-size: 0.9rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.00938em; 
}

.break {
    flex-basis: 100%;
    height: 0;
  }

  .footer {
    flex-shrink: 0;
    text-align: center;
    color: white;
    bottom: 0;
    text-align: center;
    padding: 20px 15px;
  }
  .footer p{
      margin: 0;
  }
  .goback {
    display: flex;
    justify-content: center;
    background-color: green;
    text-align: left;
  }
  
  .gobackL {
    padding: 10px;
    background-color: pink;
  }  
  
  .gobackc {
    padding: 10px;
    background-color: yellow;
    margin: 0 auto;
  }
  
  .sublistx + .sublistx::before {
    display: inline-block;
    white-space: pre;
    content: ", ";
  }
